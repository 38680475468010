import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"

export default function IndexPage() {
    return (
      <Layout>
        <SEO
          title="Amazon Accountant Directory"
          description="Find your Amazon accountant with our easy to use directory. Each of the listed accountants understand taxomate and are a great way to get started."
          image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
          pathname="/amazon-accountant-directory/"
          originalPathname="/amazon-accountant-directory/"
        />

<iframe height="850px" width="100%" frameborder="0" src="https://qwc5lrd.spread.name"></iframe>




</Layout>
  );
}
